import React, { useState, useEffect, useCallback } from 'react';
import {
  close,
  LoginWidgetModificationPayload,
  sendAccessToken,
  sendModifyLoginWidget,
  sendUserLoginAttempt,
} from '../../logic/messaging';
import { EhrCredentials, SeamlessCredentials } from '../../types';
import { SeamlessLoginInlineDisplay } from './SeamlessLoginInlineDisplay';
import { SeamlessLoginExtendedDisplay } from './SeamlessLoginExtendedDisplay';
import {
  useInlineWidgetContext,
  INLINE_WIDGET_MODIFICATION,
  InlineWidgetContextProvider,
} from './InlineWidgetContext';

import {
  analyticsClient,
  ElementInteraction,
  LoginMethods,
  VimConnectOtpLoginAnalyticsEventTypes,
  VimConnectSeamlessAnalyticsEventTypes,
} from '../../logic/analytics';
import { Templates } from '../../atomic';
import { loginWidgetLogger } from '../../logic/logger';
import { Login } from '@getvim/vim-connect';
import { LoginResult } from '../../logic/types';

export const EXPANDED_WIDGET_MODIFICATION: LoginWidgetModificationPayload = {
  size: { width: '420', height: '564' },
  alignment: { top: '0', right: '0' },
};

export interface SeamlessLoginWidgetProps {
  widgetTimeout: number;
  isSeamlessSucceeded?: boolean;
  seamlessCredentials?: SeamlessCredentials;
  ehrCredentials?: EhrCredentials;
  organizationId: number;
}

const SeamlessLoginWidgetUnWrapped: React.FC<SeamlessLoginWidgetProps> = ({
  widgetTimeout,
  isSeamlessSucceeded,
  seamlessCredentials,
  ehrCredentials,
  organizationId,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean | undefined>(undefined);
  const [loginPercentage, setLoginPercentage] = useState<number>(0);
  const [loginToVimSucceeded, setLoginToVimSucceeded] = useState<boolean>(false);
  const { context } = useInlineWidgetContext();

  useEffect(() => {
    if (loginPercentage < 99 && widgetTimeout > 0) {
      setTimeout(() => {
        setLoginPercentage(loginPercentage + 1);
      }, widgetTimeout / 100);
    }
  }, [loginPercentage, widgetTimeout]);

  useEffect(() => {
    const modification = isExpanded
      ? EXPANDED_WIDGET_MODIFICATION
      : { ...INLINE_WIDGET_MODIFICATION, ...context.modification };
    sendModifyLoginWidget(modification);
    if (isExpanded !== undefined) {
      analyticsClient.track(VimConnectSeamlessAnalyticsEventTypes.VimConnectWidgetInteraction, {
        interaction: isExpanded ? ElementInteraction.Expanded : ElementInteraction.Collapse,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  const onWidgetClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const onExpandedWidgetClose = useCallback(() => {
    if (!loginToVimSucceeded) {
      setIsExpanded(!isExpanded);
    } else {
      close(true, Login.LoginMethodType.SEAMLESS_LOGIN);
    }
  }, [isExpanded, loginToVimSucceeded]);

  const onLoginSuccess = useCallback(
    ({ accessToken: { accessToken, refreshToken } }: LoginResult) => {
      analyticsClient.track(VimConnectOtpLoginAnalyticsEventTypes.VimConnectInitialSuccessLogin, {
        login_type: LoginMethods.seamless,
      });

      loginWidgetLogger.warning('Vim Connect initial successful login', {
        login_type: LoginMethods.seamless,
      });
      sendAccessToken(accessToken, refreshToken);

      sendUserLoginAttempt(Login.LoginMethodType.SEAMLESS_LOGIN, {
        accessToken,
      });

      setLoginToVimSucceeded(true);
      close();
    },
    [],
  );

  if (isSeamlessSucceeded && !isExpanded) setIsExpanded(true);

  return !isExpanded ? (
    <SeamlessLoginInlineDisplay onWidgetClick={onWidgetClick} loginPercentage={loginPercentage} />
  ) : (
    <Templates.LoginWidgetWrapper className="login" onCloseClicked={onExpandedWidgetClose}>
      <main className="popover-body">
        <SeamlessLoginExtendedDisplay
          isSeamlessSucceeded={isSeamlessSucceeded}
          ehrCredentials={ehrCredentials}
          organizationId={organizationId}
          seamlessCredentials={seamlessCredentials}
          loginPercentage={loginPercentage}
          onSuccess={onLoginSuccess}
        />
      </main>
    </Templates.LoginWidgetWrapper>
  );
};

export const SeamlessLoginWidget: React.FC<SeamlessLoginWidgetProps> = (props) => {
  return (
    <InlineWidgetContextProvider>
      <SeamlessLoginWidgetUnWrapped {...props} />
    </InlineWidgetContextProvider>
  );
};
