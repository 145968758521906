import { sendDataToApp } from '@getvim/utils-vim-connect-communication';
import { Login } from '@getvim/vim-connect';

enum WidgetPayloadType {
  ACCESS_TOKEN = 'vim-connect-login-access-token',
  LOGIN_FAILURE = 'vim-connect-login-failure',
  CLOSE = 'vim-connect-login-close',
  MODIFY_WIDGET = 'vim-connect-modify-widget',
  SEAMLESS_LOGIN_APPROVED = 'vim-connect-seamless-login-approved',
  SEAMLESS_LOGIN_FAILED = 'vim-connect-seamless-login-failed',
  USER_LOGIN_ATTEMPT = 'vim-connect-user-login-attempt',
}

export interface LoginWidgetModificationPayload {
  size?: { width: string; height: string };
  alignment?: { top?: string; right?: string; left?: string; bottom?: string };
  clipPath?: string;
}

export const sendUserLoginAttempt = (
  loginMethod: Login.LoginMethodType,
  loginResult: {
    accessToken?: string;
    errorMessage?: string;
    attemptNumber?: number;
    isCancelled?: boolean;
  },
) =>
  sendDataToApp({
    type: WidgetPayloadType.USER_LOGIN_ATTEMPT,
    loginMethod,
    loginResult,
  });

export const sendAccessToken = (accessToken: string, refreshToken: string) =>
  sendDataToApp({
    type: WidgetPayloadType.ACCESS_TOKEN,
    accessToken,
    refreshToken,
  });

export function sendLoginFailure({ message }: Error): void {
  sendDataToApp({
    type: WidgetPayloadType.LOGIN_FAILURE,
    error: { message },
  });
}

export function sendModifyLoginWidget(modification: LoginWidgetModificationPayload): void {
  sendDataToApp({
    type: WidgetPayloadType.MODIFY_WIDGET,
    modification,
  });
}

export const sendSeamlessApproved = (): void =>
  sendDataToApp({
    type: WidgetPayloadType.SEAMLESS_LOGIN_APPROVED,
  });

export const sendFailedSeamless = ({ message }: Error, shouldRetry = true): void =>
  sendDataToApp({
    type: WidgetPayloadType.SEAMLESS_LOGIN_FAILED,
    error: { message },
    shouldRetry,
  });

export const close = (isManual = false, loginMethod?: Login.LoginMethodType) => {
  sendDataToApp({
    type: WidgetPayloadType.CLOSE,
    isManual,
  });
  if (loginMethod) {
    sendUserLoginAttempt(loginMethod, {
      isCancelled: true,
    });
  }
};
